.modal-create-bill-provider {

    .modal-body {
        padding: 2rem;
        scrollbar-gutter: stable both-edges;
    }

    .container {
        max-width: 1200px;


        .form-container {

            display: grid;
            grid-template-columns: 1fr 3fr;
            grid-template-rows: repeat(2, auto);
            grid-row-gap: 0px;
            grid-column-gap: 20px;
            margin-bottom: 10px;

            #type-container {
                grid-area: 1 / 1 / 2 / 2;
            }


            #designation-container {
                grid-area: 1 / 2 / 2 / 3;
            }

            #comment-container {
                grid-area: 2 / 1 / 3 / 3;

                display: flex;
                flex-direction: column;

                textarea {
                    resize: none;
                }
            }

        }
    }

	.modal-footer {
		#error-message {
			color: red;
			font-style: italic;
			background-color: #fff7f7;
			padding: 5px 10px;
			border-radius: 10px;
			display: flex;
			align-items: center;
			gap: 5px;
		}
	}

}