#app-content {

	&:has(.file-upload) {
		background: white;
	}

	.file-upload {
		#page-content {
			color: #3E3E3E;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;

			.col-import,
			.col-export {
				display: flex;
				justify-content: center;

				>div {
					display: flex;
					flex-direction: column;
					max-width: 515px;
					width: 100%;

					.section-title {
						margin-bottom: 1rem;
						font-weight: 700;
						font-size: 24px;
						border-bottom: 1px solid #949494;
					}

					.btn {
						min-width: 6rem;
					}

					.bold {
						font-weight: bold;
					}
				}

			}

			.col-export {
				.container-export-button {
					display: flex;
					margin-top: 1rem;

					button {
						margin-left: auto;
					}
				}
			}
		}

		#import-file-list {
			overflow: auto;
			flex-wrap: wrap;
		}

		#validation-upload {
			margin-top: 1rem;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.files-number {
			font-size: 1.5rem;
			font-style: italic;
		}

		.progress {
			margin: 1rem 0;
			border-radius: 8px;
		}

		.import-actionner {
			margin-top: 1rem;
			display: flex;
			flex-direction: row-reverse;
		}

		.upload-area {
			font-size: 1.25rem;
			background-color: #fafafa;
			position: relative;
			display: flex;

			border: 2px dashed var(--ap-primary-btn-background);
			border-radius: 8px;
			font-size: 1.25rem;
			position: relative;
			padding: 2rem;
			margin: 1rem 0;
			justify-content: center;

			.upload-input {
				display: inherit;
				align-items: center;
				flex-direction: column;

				.input-file {
					width: 0.1px;
					height: 0.1px;
					opacity: 0;
					overflow: hidden;
					position: absolute;
					z-index: -1;
				}

				.input-file+label {
					cursor: pointer;
					margin-bottom: 1rem;
				}
			}
		}

		#upload-file-list {
			overflow: auto;
			flex-wrap: wrap;
			background-color: white;
		}

		.file-item-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 5px 5px 5px 0px;
			padding: 5px;
			border: solid 2px;
			border-radius: 2px;
			box-shadow: 1px 2px;
			gap: 10px;
			border-radius: 8px;

			.name {
				font-weight: bold;
			}

			.close {
				display: inline-block;
				padding: 10px 20px;
				font-size: 16px;
				color: rgb(0, 0, 0);
				background-color: #ffffff;
				border: none;
				border-radius: 5px;
				cursor: pointer;
				text-align: center;
				user-select: none;
				transition: background-color 0.3s, transform 0.1s;
			}

			.close:hover {
				background-color: #cacaca;
			}

			.close:active {
				background-color: #888888;
				transform: translateY(2px);
			}
		}

		.box-drag-and-drop,
		.box-uploading,
		.box-success,
		.box-error {
			display: none;
		}

	}

}