ap-input-bill-due {
	display: flex;
	margin-left: 20px;

	input {
		height: 34px;
		border-radius: 8px;
		border: 1px solid #DBDDE4;
		padding: 4px;


		&:focus {

			outline: none;
		}
	}

	.content-checkbox {
		margin-left: 20px;
		display: none;
		align-items: center;

		label {
			margin: 0;
			margin-right: 4px;
		}

		.icon {
			font-size: 16px;
			cursor: pointer;
		}

		input {
			margin-left: 10px;
			width: 16px;
		}
	}

}